<template>

    <div class="popup active">
        <div class="notice_popup human">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
            </div>
            
			<div class="tit" style="margin-left: 0;"><img style="vertical-align: middle;" src="/images/caution.png" alt="알림"/>죄송합니다!</div>
            <div class="member" style="line-height: 30px;">회원가입을 하시면<br/>다양한 정보를 확인하실 수 있습니다!</div>

            <div class="select-btn">
                <div class="btn" @click="$emit('close', false)">취소</div>
                <div class="btn" @click="$emit('close', true)">회원가입</div>
            </div>
        </div>
    </div>

</template>

<script>

export default {

	components : {		
	},

	props: ['param'],

	data() {
		return {		
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');		
	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {		
	},
};
</script>
